function loadChildrensWidgets() {
    var url = location.href.replace(location.hash, '');
    // url = url.replace('wwwtest', 'www');
    if (location.search === '?lang=es') {
        url = url.replace('www', 'es');
    }
    url = url.replace(location.search, '');
    url = url.replace(/\/$/, '');
    $.get('/wps/dotcom_service/getPageBanner?url=' + url)
        .done(function(response) {
            var data = response,
                numWidgets = data.elements ? data.elements.length : 0,
                i = 0;
            for (i; i < numWidgets; i++) {
                processWidget(data.elements[i]);
            }
            if (data.mainJs) {
                // console.log('setup script');
            }
            if (data.mainCss) {
                // console.log('apply CSS');
            }
        })
        .fail(function(err) {
            console.error(err);
        });
    function processWidget(widget) {
        // console.log(widget);
        var styleTag = widget.cssPath ? '<link rel="stylesheet" href="' + widget.cssPath + '" />' : '';
        var markup = widget.englishHTML ? widget.englishHTML : widget.spanishHTML;
        var scriptTag = widget.jsPath ? '<script src="' + widget.jsPath + '"></script>' : '';
        var html;
        html = styleTag + markup + scriptTag;
        $('body').append(html);
    }
}