// Get Q Params
function reqParamVal(param) {
    var qStrVal = window.location.hash.match(new RegExp(param + "=([^#]*)"));
    qStrVal = (qStrVal) ? qStrVal[1] : "";
    return qStrVal;
}
function getCookieDotCom(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}
function setCookieDotCom(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";path=/;domain=.childrens.com;";
}
function deleteCookieDotCom(cname) {
    document.cookie = cname + "=;path=/;domain=.childrens.com;expires=Thu,01-Jan-70 00:00:01 GMT;";
}
function setCookieDotComCh(cname, cvalue, hours) {
    var date = new Date();
    date.setTime(+ date + (hours * 86400000)); //60 * 60 * 1000
    document.cookie = cname + "=" + cvalue + ";path=/;domain=.childrens.com;expires="+date.toGMTString()+";";
}
function setOrganic(refUrl) {
    return refUrl.indexOf("www.google.com") >= 0 || refUrl.indexOf("search.yahoo.com") >= 0 || refUrl.indexOf("www.bing.com") >= 0;
}
function hasReferral() {
    return getCookieDotCom('ch_utm_medium') == "referral";
}
function createCookies(medium, source, campaign, length) {
    setCookieDotComCh('ch_utm_medium', medium, length);
    setCookieDotComCh('ch_utm_source', source, length);
    setCookieDotComCh('ch_utm_campaign', campaign, length);
}
$.urlParam = function(name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null){
        return null;
    } else {
        return results[1] || 0;
    }
}
