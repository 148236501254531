function initLanguages() {
    var path;

    CH.languages = new Languages();
    function Languages() {
        var that = this;
        // this.translations = false;
        this.$header = $("header.header");
        this.$languageToggle = $('button.language-toggle');
        this.$languageSwitcherBar = $('.language-switcher .bar');
        this.$languageForm =  $('.language-switcher form:first-of-type');
        this.$spanishForm =  $('#spanish-language-switcher');
        this.lsm = matchMedia('(max-width: 1224px)');

        this.isTranslatedPage = function() {
            return window.location.href.indexOf('es.childrens.com') > -1;
        }

        this.translatedPagePath = function() {
            var path = typeof window.spanishTranslation !== 'undefined' ? window.spanishTranslation : '';
            // var search = location.search;
            // var hash = location.hash;
            if ('' !== path) {
                path = location.href.replace(/https:\/\/(www|es)(test)?\.childrens\.com/i, '');
                path = path.replace(/http:\/\/portalauthv2tst(:10039)?\/wps\/(my)?portal\/(childrenspublic|sandbox)\/childrenshome(\/childrens)?/, '');
                if (path.indexOf('/!ut') > -1) {
                    path = path.slice(0, path.indexOf('/!ut'));
                }
                // path += search + hash;
            }
            return path;
        }

        this.userWantsTranslations = function() {
            return window.getCookieDotCom('language') == 'spanish' ? true : false;
        }

        this.redirectToSpanish = function(path) {
            // alert('user chose translations, redirecting to https://es.childrens.com' + path);
            window.location.href = 'https://es.childrens.com' + path;
        }

        this.redirectToEnglish = function(path) {
            // alert('user chose English - redirecting to https://www.childrens.com' + path);
            window.location.href = 'https://www.childrens.com' + path;
        }

        this.toggleLanguageSwitcher = function(e) {
            var fadeOut = (typeof e !== 'undefined' && e === 'fadeOut') ? true : false;
            // console.log(e);
            if (this.$languageSwitcherBar.hasClass('active') && this.lsm.matches) {
                this.$languageSwitcherBar.fadeOut(2000, function() {
                    $(this).removeClass('active');
                    $(this).css('display', '');
                });
            } else {
                this.$languageSwitcherBar.fadeIn(300, function() {
                    $(this).addClass('active');
                    $(this).css('display', '');
                    if (fadeOut) {
                        setTimeout(function() {that.toggleLanguageSwitcher.call(that)}, 3000);
                    }
                });
            }
        }

        this.hideLanguageSwitcher = function(e) {
            // console.log(e);
            var $target = $(e.target);
            var isSwitcherChild = 1;
            if (!this.$languageSwitcherBar.hasClass('active') || CH.nav.mql.matches) return;
            isSwitcherChild = $target.closest('.bar').closest('.language-switcher').length;
            if (isSwitcherChild) return;
            this.$languageSwitcherBar.fadeOut(500, function() {
                $(this).removeClass('active');
                $(this).css('display', '');
            });
        }

        $('body').on('click', function(e) {
            that.hideLanguageSwitcher.call(that, e)
        });

        this.$languageForm.find('select').on('change', function(e) {
            var $submit = that.$languageForm.find('input[type="submit"]');

            if ($(this).val() === 'spanish') {
                $submit.removeAttr('disabled').prop('disabled', false).trigger('focus');
            } else {
                $submit.attr('disabled', 'disabled').prop('disabled', true);
            }
        })

        this.$spanishForm.find('select').on('change', function(e) {
            var $submit = that.$spanishForm.find('input[type="submit"]');

            if ($(this).val() === 'english') {
                $submit.removeAttr('disabled').prop('disabled', false).trigger('focus');
            } else {
                $submit.attr('disabled', 'disabled').prop('disabled', true);
            }
        })

        this.$languageForm.on('submit', function(e) {
            e.preventDefault();
            window.setCookieDotComCh('language', 'spanish', 365);
            that.redirectToSpanish(path);
        })

        this.$spanishForm.on('submit', function(e) {
            e.preventDefault();
            window.deleteCookieDotCom('language');
            that.redirectToEnglish(path);
        })

        this.$languageToggle.on('click', function(e) {
            // console.log('click on language toggle');
            that.toggleLanguageSwitcher.call(that, e);
        });

        this.lsm.addListener(function() {
            // console.log('media greater than 1225');
            that.toggleLanguageSwitcher.call(that, 'fadeOut');
        });

        path = this.translatedPagePath();
        // console.log(path);

        if (this.isTranslatedPage() || ( path && !this.userWantsTranslations())) {
            this.$header.addClass('lang-switch-active');
            if (this.lsm.matches) {
                this.toggleLanguageSwitcher.call(this, 'fadeOut');
            }
        } else if (path && this.userWantsTranslations() && !this.isTranslatedPage()) {
            this.redirectToSpanish(path);
        } else if (!path && this.userWantsTranslations()) {
            this.$header.addClass('lang-switch-active');
            $(".language-switcher").addClass("no-translation-available");
            if (this.lsm.matches) {
                this.toggleLanguageSwitcher.call(this, 'fadeOut');
            }
        }

        return this;
    }
}