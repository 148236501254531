 /*! set-tracking-fields.js */

function initSetTrackingFields() {
  if ( isFormPresent() ) {
    parseCookies();
  }
}

////////////////////////////////////////////////////////////////////////

function isFormPresent() {
  return !!$( 'form' ).length;
}

////////////////////////////////////////////////////////////////////////

function parseCookies() {
  var allCookiesArray = document.cookie.split( '; ' );

  for ( cookie in allCookiesArray ) {
    populateFormField( allCookiesArray[cookie] );
  }
}

////////////////////////////////////////////////////////////////////////

function populateFormField( cookie ) {
  var $gaCampaignInput = $( 'input[name="utm_campaign"]' ),
      $gaCidInput = $( 'input[name="ga_cid"]' ),
      $gaMediumInput = $( 'input[name="utm_medium"]' ),
      $gaSourceInput = $( 'input[name="utm_source"]' ),
      equalsPosition = cookie.indexOf( '=' ),
      gaCampaignCookieName = 'ch_utm_campaign',
      gaMediumCookieName = 'ch_utm_medium',
      gaSourceCookieName = 'ch_utm_source',

      cookieName = cookie.substring( 0, equalsPosition ),
      cookieValue = decodeURIComponent( cookie.substring( equalsPosition + 1 ));

  if ( !cookieValue ) cookieValue = '';

  switch ( cookieName ) {
    case gaCampaignCookieName :
      $gaCampaignInput.val( cookieValue );
      break;
    case gaMediumCookieName :
      $gaMediumInput.val( cookieValue );
      break;
    case gaSourceCookieName :
      $gaSourceInput.val( cookieValue );
  }

  $gaCidInput.val( getGAClientId() );
}

////////////////////////////////////////////////////////////////////////

/**
 * Detect the Google Analytics client ID.
 *
 * @see https://stackoverflow.com/questions/20053949/how-to-get-the-google-analytics-client-id#answer-20054201
 *
 * @returns Google Analytics Client ID
 **/
function getGAClientId() {
  var ga_cid='';
  if ( typeof ga === 'function' && typeof ga.getAll === 'function' ) {
    var ga_data = ga.getAll();

    if ( typeof ga_data === 'object' && typeof ga_data[ 0 ] === 'object' && typeof ga_data[ 0 ].get === 'function' ) {
        ga_cid = ga_data[ 0 ].get( 'clientId' );
    }
  }
  return ga_cid;
}
