var Expand = function() {
    var a;
    return {
        settings: {
            expandToggle: $(".js-expand-toggle"),
            externalTrigger: $(".js-external-trigger"),
            expandOnReady: $("[data-expand-on-ready]")
        },
        init: function() {
            a = this.settings,
                this.bindUIActions(),
            a.expandOnReady.length && this.triggerConditionalExpands()
        },
        bindUIActions: function() {
            a.expandToggle.on("click", function(a) {
                Expand.triggerActions($(this), a)
            }),
                a.externalTrigger.on("click", function(a) {
                    var b = $($(this).data("trigger"));
                    a.preventDefault(),
                        b.trigger("click")
                })
        },
        triggerActions: function(a, b) {
            var c = a.data("expand-item")
                , d = a.data("collapse")
                , e = a.closest(".js-expand-wrap")
                , f = c ? $(c) : e.children(".js-expand-item")
                , g = d ? $(d) : null
                , h = a.data("expand-context") ? a.data("expand-context").split("|") : [""];
            b && b.stopPropagation(),
            Media.meetsContext(h) && (b && b.preventDefault(),
            g && g[0] !== f[0] && Expand.collapse(g, g.closest(".js-expand-wrap"), h, a),
            f && Expand.toggle(f, e, h, a))
        },
        triggerConditionalExpands: function() {
            a.expandOnReady.each(function() {
                var a = $(this)
                    , b = a.data("expand-on-ready").split("|");
                Media.meetsContext(b) && Expand.triggerActions(a)
            })
        },
        toggle: function(a, b, c, d) {
            c || (c = [""]);
            for (var e = 0; e < c.length; e++) {
                var f = c[e].length ? "-" + c[e] : "";
                a && a.toggleClass("is-expanded" + f).toggleClass("is-collapsed" + f),
                b && b.toggleClass("module-is-expanded" + f).toggleClass("module-is-collapsed" + f),
                d && d.toggleClass("is-toggled" + f)
            }
        },
        expand: function(a, b, c, d) {
            c || (c = [""]);
            for (var e = 0; e < c.length; e++) {
                var f = c[e].length ? "-" + c[e] : "";
                a && a.addClass("is-expanded" + f).removeClass("is-collapsed" + f),
                b && b.addClass("module-is-expanded" + f).removeClass("module-is-collapsed" + f),
                d && d.addClass("is-toggled" + f)
            }
        },
        collapse: function(a, b, c, d) {
            c || (c = [""]);
            for (var e = 0; e < c.length; e++) {
                var f = c[e].length ? "-" + c[e] : "";
                a && a.removeClass("is-expanded" + f).addClass("is-collapsed" + f),
                b && b.removeClass("module-is-expanded" + f).addClass("module-is-collapsed" + f),
                d && d.removeClass("is-toggled" + f)
            }
        }
    }
}();
Expand.init();
var Breadcrumbs = function(a) {
    var b, c = $(".js-nav-breadcrumbs");
    return {
        settings: {
            expandToggles: c.find(".js-nav-breadcrumbs-expand-toggle"),
            mobileToggle: $(".js-nav-breadcrumbs-toggle"),
            mobileNavOpen: !1
        },
        init: function() {
            c && c.length && (b = $.extend({}, this.settings, a),
                this.bindUIActions(),
                this.checkLastChild(),
                this.addFallbackClasses())
        },
        bindUIActions: function() {
            b.mobileToggle.on("click", function() {
                window.HeaderForms && HeaderForms.close(),
                    b.mobileNavOpen ? Breadcrumbs.closeMobileNav() : Breadcrumbs.openMobileNav()
            }),
                b.expandToggles.on("click", Breadcrumbs.toggleAccordionOrFollowLink)
        },
        closeMobileNav: function() {
            c.removeClass("is-active"),
                b.mobileToggle.removeClass("is-active"),
                b.mobileNavOpen = !1
        },
        openMobileNav: function() {
            c.addClass("is-active"),
                b.mobileToggle.addClass("is-active"),
                b.mobileNavOpen = !0
        },
        toggleAccordionOrFollowLink: function(a) {
            var b = $(this)
                , d = b.closest(".js-expand-wrap")
                , e = d.find(".js-expand-item")
                , f = c.find(".js-expand-item.is-expanded")
                , g = f.closest(".js-expand-wrap");
            a.preventDefault(),
                Expand.toggle(e, d),
            f.length && Expand.collapse(f, g)
        },
        checkLastChild: function() {
            var a = $(".nav-breadcrumbs-level1 > li:last-child");
            "" === $.trim(a.find(".nav-breadcrumbs-level2").html()) && a.find(".nav-breadcrumbs-toggle-text").css("background-image", "none")
        },
        addFallbackClasses: function() {
            $(".nav-breadcrumbs-level1>li:nth-child(1)").addClass("nth-child-1"),
                $(".nav-breadcrumbs-level1>li:nth-child(2)").addClass("nth-child-2"),
                $(".nav-breadcrumbs-level1>li:nth-child(3)").addClass("nth-child-3"),
                $(".nav-breadcrumbs-level1>li:nth-child(4)").addClass("nth-child-4"),
                $(".nav-breadcrumbs-level1>li:nth-child(5)").addClass("nth-child-5"),
                $(".nav-breadcrumbs-level1>li:nth-child(6)").addClass("nth-child-6")
        }
    }
}();
Breadcrumbs.init();
var ColSort = function(a) {
    var b, c = $(".js-colsort");
    return {
        init: function() {
            c && c.length && (b = $.extend({}, this.settings, a),
                c.each(function() {
                    ColSort.createCols($(this), {})
                }))
        },
        createCols: function(a, b) {
            var c = a[0].tagName.toLowerCase()
                , d = b.children || a.children()
                , e = d.length
                , f = b.colTotal || a.data("col-total")
                , g = b.colWrap || a.data("col-wrap")
                , h = e % f
                , i = Math.floor(e / f)
                , j = 0;
            ("ul" === c || "ol" === c) && d.unwrap();
            for (var k = 0; f > k; k++) {
                var l = h > k ? 1 : 0
                    , m = j + i + l;
                d.slice(j, m).wrapAll(g),
                    j = m
            }
            a.addClass("is-sorted")
        }
    }
}();
ColSort.init();
var Social = function(a) {
    var b;
    return {
        settings: {
            printToggle: $(".js-social-print")
        },
        init: function() {
            b = $.extend({}, this.settings, a),
            b.printToggle && b.printToggle.length && this.bindUIActions()
        },
        bindUIActions: function() {
            b.printToggle.on("click", function() {
                window.print()
            })
        }
    }
}();
Social.init();
