window.CH = window.CH || {};
$(init);
function init() {
    initNav();
    initLanguages();
    cookiesAndTracking();
    setTimeout(loadChildrensWidgets, 1000);
}
function cookiesAndTracking() {
    var referrer = document.referrer,
        utmCampCd = $.urlParam('utm_campaign'),
        utmMediumCd = $.urlParam('utm_medium'),
        utmSourceCd = $.urlParam('utm_source'),
        emailSrcCode = $.urlParam('source_code'),
        isOrganic = setOrganic(referrer),
        isNotChildrens = referrer.indexOf("childrens.com") === -1,
        medium;
    if (utmCampCd || utmMediumCd || utmSourceCd) {
        createCookies(utmMediumCd, utmSourceCd, utmCampCd, 7);
    } else {
        if (referrer && isNotChildrens) {
            if (isOrganic) {
                if (hasReferral()) {
                    medium = false;
                } else {
                    medium = 'organic'
                }
            } else {
                medium = 'referral'
            }
            if (medium) {
                createCookies(medium, referrer, '', 7);
            }
        }
    }
    if (emailSrcCode) {
        setCookieDotCom('email_src', emailSrcCode);
    }

    // Check the DOM for forms and populate hidden field values
    initSetTrackingFields();
}
