var fusionUrl = '/wps/FusionService/api/public/query/Childrens/';

if (window.location.href.indexOf('es.childrens.com') !== -1) {
    fusionUrl = 'https://www.childrens.com' + fusionUrl;
}

// typeahead options
var navSuggestions = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url:  fusionUrl + 'Childrens_Suggest',
        prepare: function (query, settings) {
            settings.url = settings.url + '?q=' + query;
            settings.headers = {
                // "Authorization": "Basic " + btoa('searchuser:pubpass123')
            };
            return settings;
        },
        transform: function (response) {
            var results = [];
            var i;
            if (response.terms.suggest_shingle.length > 0) {
                for (i = 0; i < response.terms.suggest_shingle.length; i++) {
                    if (i < 5) {
                        results.push({
                            type: 'suggestion',
                            value: response.terms.suggest_shingle[i][0]
                        });
                    }
                }
            }
            if (response.response.docs.length > 0) {
                for (i = 0; i < response.response.docs.length; i++) {
                    if (i < 5) {
                        results.push({
                            type: 'page',
                            value: response.response.docs[i].label,
                            url: response.response.docs[i].url
                        });
                    }
                }
            }

            return results;
        }
    }
});

function initGlobalTypeahead(searchFrom) {
    var $searchTerm = searchFrom === 'mobile' ? $('#mobile-searchterm') : $('#header-searchterm');

    $searchTerm.typeahead({
        hint: false,
        highlight: true,
        minLength: 3
    },
    {
        name: 'suggestions',
        source: navSuggestions,
        limit: 10,
        display: function(item) {
            return item.value;
        },
        templates: {
            suggestion: function(item) {
                if (item.type === 'suggestion') {
                    return '<div class="typeahead-suggestion typeahead-word">'+ item.value +'</div>';
                } else if (item.type === 'page') {
                    return '<div class="typeahead-suggestion typeahead-page"><span>' + item.value +'</span><span class="angle-right"></span></div>';
                }
            }
        }
    }).on('typeahead:render', function(ev, suggestions) {
        if (suggestions && suggestions.length > 0) {
            $searchTerm.add('.header-search').addClass('suggestions-active');
        }
    }).on('typeahead:active', function(ev, suggestion) {
        if (ev.target.value != null && ev.target.value.length > 0) {
            $searchTerm.add('.header-search').addClass('suggestions-active');
        }
    }).on('typeahead:close', function(ev, suggestion) {
        $searchTerm.add('.header-search').removeClass('suggestions-active');
    }).on('typeahead:select', function(ev, suggestion) {
        if (suggestion.type === 'suggestion') {
            window.location.href = '/search?q=' + suggestion.value;
        } else {
            window.location.href = suggestion.url;
        }
    }).on('keyup', this, function (event) {
        var $dropdown = $('.tt-menu');
        if ($dropdown.hasClass('tt-empty')) {
            $searchTerm.add('.header-search').removeClass('suggestions-active');
        } else {
            $searchTerm.add('.header-search').addClass('suggestions-active');
        }
        if (event.keyCode === 13) {
            $searchTerm.typeahead('close');
        }
    });
}

$(function() {
    $('#header-searchbar').on('submit', doSearch);
    $('#mobile-searchbar').on( 'submit', doSearch);

    function doSearch(e) {
        var searchUrl = '/search',
            q = $(this).find('.header-search-input').val() || '*';

        if (window.location.href.indexOf('es.childrens.com') !== -1) {
            searchUrl = 'https://www.childrens.com/search';
        }

        window.location.href = q === '*' ? searchUrl : searchUrl + '?q=' + q;
        return false;
    }

    initGlobalTypeahead('mobile');
    initGlobalTypeahead('header');
});
